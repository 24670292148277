import React from "react"
import {
  StoriesModuleContainer,
  Title,
  MainImg,
  StoriesBlock,
  ImgWrapper,
} from "./style"
import { Link } from "gatsby"
import { ReactComponent as Arrow } from "images/icons/right-arrow.svg"

const StoriesModule = ({ story, title }) => {
  // const data = useStaticQuery(
  //   graphql`
  //     query {
  //       file(relativePath: { eq: "stories-home-placeholder.png" }) {
  //         childImageSharp {
  //           fluid(maxWidth: 1440, quality: 100) {
  //             ...GatsbyImageSharpFluid_withWebp_noBase64
  //           }
  //         }
  //       }
  //     }
  //   `
  // )

  return (
    <>
      <StoriesModuleContainer>
        <Title>{title}</Title>
        <ImgWrapper>
          <MainImg fluid={story.featuredImage} />
        </ImgWrapper>
        <StoriesBlock>
          <h3>
            {" "}
            <Link to={story.link}>{story.title}</Link>
          </h3>
          <p dangerouslySetInnerHTML={{ __html: story.excerpt }} />

          <span className="author">{story.author.name}</span>
          <Arrow />
        </StoriesBlock>
      </StoriesModuleContainer>
    </>
  )
}

export default StoriesModule

StoriesModule.defaultProps = {
  title: "Our thoughts",
}
