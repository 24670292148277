import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useState } from "react"
import { StoriesModule } from "../components/StoriesModule"
import { Stories2ndRow } from "components/Stories2ndRow"
import { LoadMore } from "../components/LoadMore"
import { useStaticQuery, graphql } from "gatsby"
import { device } from "../utils/breakpoints"

import styled from "styled-components"

const StoriesIndex = styled.div`
  padding-bottom: 40px;

  @media ${device.desktop} {
    padding-bottom: 90px;
  }
`

const IndexPage = ({ pageContext }) => {
  const initialData = useStaticQuery(graphql`
    {
      wpgraphql {
        posts(first: 100) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodes {
            title
            excerpt
            link
            featuredImage {
              sourceUrl
              srcSet
              mediaDetails {
                width
                height
              }
            }
            author {
              name
            }
          }
        }
      }
    }
  `)

  const initialPosts = initialData.wpgraphql.posts.nodes

  const { title } = pageContext

  const [posts, setPosts] = useState(initialPosts.slice(1))
  const [pageInfo, setPageInfo] = useState(initialData.wpgraphql.posts.pageInfo)

  const loadMore = () => {
    const cursor = pageInfo.endCursor

    fetch(process.env.GATSBY_GRAPHQL_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `
        {
          posts(first: 2, after: "${cursor}") {
            pageInfo {
              endCursor
              hasNextPage
            }
            nodes {
              title
              excerpt
              link
              featuredImage {
                sourceUrl
                srcSet
                mediaDetails {
                  width
                  height
                }
              }
              author {
                name
              }
            }
          }
        }
        
    `,
      }),
    })
      .then(res => res.json())
      .then(res => {
        setPosts([...posts, ...res.data.posts.nodes])
        setPageInfo(res.data.posts.pageInfo)
      })
  }

  return (
    <Layout>
      <SEO title={title} />
      <StoriesIndex>
        <StoriesModule {...{ title }} story={initialPosts[0]} />
        <Stories2ndRow stories={posts} />

        {pageInfo.hasNextPage && <LoadMore handleLoadMore={() => loadMore()} />}
      </StoriesIndex>
    </Layout>
  )
}

export default IndexPage
