import React from "react"
import { TeaserLarge } from "components/TeaserLarge"

import {
  NewFlexGrid,
  NewFlexRow,
  NewFlexCol,
  Stories2ndRowContainer,
} from "./style"

const Stories2ndRow = ({ stories }) => {
  return (
    <Stories2ndRowContainer stories={stories}>
      <NewFlexGrid gap={[20, 30, 60]} stories={stories}>
        <NewFlexRow>
          {stories.map((data, index) => {
            return (
              <NewFlexCol xs={12} sm={6} key={index}>
                <TeaserLarge
                  title={data.title}
                  link={data.link}
                  excerpt={data.excerpt}
                  tag={data.author?.name || null}
                  img={data.featuredImage || data.image}
                />
              </NewFlexCol>
            )
          })}
        </NewFlexRow>
      </NewFlexGrid>
    </Stories2ndRowContainer>
  )
}

export default Stories2ndRow
