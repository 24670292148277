import styled from "styled-components"
import { device } from "breakpoints"
import { ReactComponent as Arrow } from "../../images/icons/arrow-down-green.svg"

export const Block = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: center;
  flex-direction: column;
  padding: 36px 0 0;

  @media ${device.desktop} {
    padding: 68px 0 0;
  }

  :hover {
    svg {
      transform: scale(1.1);

      circle {
        stroke: ${props => props.theme.blue};
      }

      path {
        fill: ${props => props.theme.blue};
      }
    }
  }
`

export const Label = styled.span`
  color: ${props => props.theme.primaryColor};
  font-weight: 500;
  font-size: 14px;
  margin-top: 8px;
  display: block;

  @media ${device.tablet} {
    font-size: 16px;
  }
`
export const Icon = styled(Arrow)`
  width: 36px;
  height: 36px;
  letter-spacing: 0.2px;
  transition: 250ms;
  transform: scale(1);
  @media ${device.tablet} {
    width: 40px;
    height: 40px;
  }
`

export const Button = styled.button`
  background: none;
  box-shadow: none;
  border: none;
  text-align: center;
  padding: 0;
  outline: none;
  cursor: pointer;
`
