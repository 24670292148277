import React from "react"
import PropTypes from "prop-types"

import { LargeTeaser } from "./style"

const TeaserLarge = ({
  title,
  excerpt,
  categories,
  tag,
  date,
  img,
  link,
  theme,
}) => {

  return (
    <LargeTeaser
      title={title}
      excerpt={excerpt}
      categories={categories}
      tag={tag}
      date={date}
      iconName={"right-arrow"}
      img={{...img, sizes: "(min-width: 1024px) 550px, 100vw"}}
      link={link}
      internalLink
      theme={theme}
    />
  )
}

export default TeaserLarge

TeaserLarge.defaultProps = {
  title: "A title",
  tag: ["A tag"],
  img: null,
  date: "",
}

TeaserLarge.propTypes = {
  title: PropTypes.string,
  tag: PropTypes.string,
  excerpt: PropTypes.string,
}
