import styled from "styled-components"
import { device } from "breakpoints"
import AvenueImg from "components/AvenueImg"
import { MainColumn } from "components/MainColumn"

export const StoriesModuleContainer = styled(MainColumn)`
  margin-top: 90px;
  margin-bottom: 30px;
  position: relative;

  @media ${device.desktop} {
    margin-top: 140px;
    margin-bottom: 70px;
  }
`

export const Title = styled.h1`
  color: ${props => props.theme.blue};
  font-size: 30px;
  margin-bottom: 30px;

  @media ${device.tablet} {
    margin-bottom: 75px;
  }
  @media ${device.desktop} {
    font-size: 42px;
    margin-bottom: 110px;
  }
`

export const ImgWrapper = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  background: #12009d;
  transform: translateX(-50%);
  max-width: 1440px;
  @media ${device.tablet} {
    height: 450px;
  }

  @media ${device.desktop} {
    height: 700px;
  }
`

export const MainImg = styled(AvenueImg)`
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1440px;
  @media ${device.tablet} {
    height: 450px;
  }

  @media ${device.desktop} {
    height: 700px;
  }
`

export const StoriesBlock = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 34px 30px;
  padding-bottom: 38px;
  margin-top: -40px;
  z-index: 2;
  background: ${props => props.theme.blue};

  @media ${device.tablet} {
    position: absolute;
    top: 110px;
    max-width: 360px;
  }
  @media ${device.desktop} {
    padding: 60px;
    padding-bottom: 70px;

    top: 150px;
    max-width: 550px;
  }

  :hover {
    svg {
      transform: scale(1.12);
    }
  }

  h3 {
    color: ${props => props.theme.green};
    font-size: 28px;
    margin-bottom: 14px;
    line-height: 115%;

    @media ${device.tablet} {
      width: 70%;
    }
    @media ${device.desktop} {
      font-size: 36px;
      margin-bottom: 25px;
    }

    a {
      color: inherit;
      :before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  p {
    color: white;
    font-size: 16px;
    margin-bottom: 50px;
    padding-right: 15px;
    @media ${device.desktop} {
      font-size: 18px;
      margin-bottom: 176px;
    }
  }

  span.author {
    font-size: 12px;
    color: white;

    @media ${device.desktop} {
      font-size: 14px;
    }
  }

  svg {
    position: absolute;
    right: 30px;
    bottom: 28px;
    transform: scale(1);
    transition: 420ms;
    height: 40px;
    width: 40px;
    z-index: -1;
    @media ${device.desktop} {
      right: 60px;
      bottom: 63px;
    }
  }
`
