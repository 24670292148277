import styled from "styled-components"
import { device } from "breakpoints"
import { Button } from "../Button"
import { Label } from "../Button/style"

import { FlexGrid, FlexRow, FlexCol } from "../FlexGrid"

export const Stories2ndRowContainer = styled.div`
  padding-bottom: ${props => (props.stories ? "45px" : "0")};
  @media ${device.desktop} {
    padding-bottom: ${props => (props.stories ? "0" : "0")};
  }
`
export const NewFlexGrid = styled(FlexGrid)`
  ${'' /* margin-bottom: ${props => (props.stories ? "45px" : "0")};

  @media ${device.desktop} {
    margin-bottom: ${props => (props.stories ? "0" : "-10px")};
  } */}
`
export const NewFlexRow = styled(FlexRow)`

`
export const NewFlexCol = styled(FlexCol)`
  margin-bottom: 0 !important;
`
export const LoadMore = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const LoadButton = styled(Button)`
  margin: 0 auto;
  border: 0;
  position: relative;

  ${Label} {
    font-size: 14px;
    color: #0000ff;
    letter-spacing: 0.17px;
    text-align: center;
    line-height: 32px;

    @media ${device.desktop} {
      font-size: 16px;
      letter-spacing: 0.2px;
      text-align: center;
    }
  }
  svg {
    position: absolute;
    top: -40px;
    left: 35px;
    transform: rotate(90deg);
    transition: 420ms;
    height: 40px;
    width: 40px;

    path {
      fill: #00ff00;
    }
  }
`
