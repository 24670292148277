import React from "react"
import { Block, Label, Icon, Button } from "./style"

const LoadMore = ({ handleLoadMore }) => {
  return (
    <Block>
      <Button onClick={handleLoadMore}>
        <Icon />
        <Label>Load more</Label>
      </Button>
    </Block>
  )
}

export default LoadMore
