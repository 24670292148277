import styled from "styled-components"
import { device } from "breakpoints"
import { Teaser } from "../Teaser"
import {
  ImageContainer,
  InfoContainer,
  WrappingLink,
  Title,
  Excerpt,
  Tag,
} from "../Teaser/style"

export const LargeTeaser = styled(Teaser)`
  @media ${device.desktop} {
    /* margin-top: 30px; */
    margin-bottom: ${props => (props.theme === "index" ? `0` : "0")};
  }
  ${ImageContainer} {
    margin-bottom: 0;
    padding-top: 73%;

    @media ${device.desktop} {
      padding-top: 63%;
    }
  }

  ${WrappingLink} {
    width: auto;
  }
  ${Title} {
    font-weight: 700;
    font-size: 20px;
    color: #1c00ff;
    letter-spacing: -0.15px;
    line-height: 24px;
    display: block;
    background-color: #fff;
    margin-bottom: 0;
    min-height: 52px;
    padding-top: 20px;
    padding-right: 24px;
    max-width: ${props => (props.theme === "index" ? `100%` : `80%`)};

    @media ${device.desktop} {
      font-size: ${props => (props.theme === "index" ? `36px` : "24px")};
      letter-spacing: -0.19px;
      line-height: ${props => (props.theme === "index" ? `1.1` : `30px`)};
      padding-top: 27px;
      padding-right: 27px;
    }
  }
  ${InfoContainer} {
    transform: ${props =>
      props.theme === "index" ? `none` : `translateY(-50px) translateX(-1px)`};
    padding-right: 15px;
    margin-bottom: ${props => (props.theme === "index" ? `0` : `-27px`)};

    @media ${device.desktop} {
      transform: ${props =>
        props.theme === "index" ? `none` : `translateY(-62px) translateX(-px)`};
    }
  }
  ${Excerpt} {
    font-weight: 300;
    font-size: 16px;
    color: #656565;
    letter-spacing: 0.44px;
    line-height: 24px;
    margin-top: 10px;
    order: 1;

    @media ${device.desktop} {
      font-size: 18px;
      letter-spacing: 0.5px;
      line-height: 27px;
    }
  }
  ${Tag} {
    font-weight: 500;
    font-size: 12px;
    color: ${props => (props.theme === "index" ? `9E9DA8` : `#656565`)};
    letter-spacing: 0.33px;
    line-height: 1.4;
    margin-bottom: 0;
    margin-top: 13px;
    order: 2;

    @media ${device.desktop} {
      font-size: 14px;
      letter-spacing: 0.39px;
      line-height: ${props => (props.theme === "index" ? `1.4` : `27px`)};
      margin-top: ${props => (props.theme === "index" ? `8px` : `25px`)};
    }
  }
`
